import React, { useState } from 'react';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { Swiper, SwiperClass, SwiperSlide } from '@/components/Slider/Swiper';

// =================================================================

interface ScrollToItemSliderProps extends ClassNameProps, ChildrenProps {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

// =================================================================

export const ScrollToItemSlider = (props: ScrollToItemSliderProps) => {
  const { xs, sm, md, lg, xl, children, className } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleClick = (index: number) => {
    setActiveIndex(index);
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  return (
    <div>
      <Swiper
        wrapperTag="ul"
        spaceBetween={18}
        onSlideChange={handleSlideChange}
        mousewheel={{
          forceToAxis: true,
          thresholdDelta: 25,
          thresholdTime: 50,
        }}
        onSwiper={setSwiper}
        breakpoints={{
          0: {
            slidesPerView: xs,
            slidesPerGroup: xs,
          },
          430: {
            slidesPerView: sm,
            slidesPerGroup: sm,
          },
          576: {
            slidesPerView: md,
            slidesPerGroup: md,
          },
          768: {
            slidesPerView: lg,
            slidesPerGroup: lg,
          },
          992: {
            slidesPerView: xl,
            slidesPerGroup: xl,
          },
        }}
        className={className}
      >
        {React.Children.map(children, (child, index) => {
          if (child) {
            return (
              <SwiperSlide tag="li" onClick={() => handleClick(index)}>
                {child}
              </SwiperSlide>
            );
          }
          return null;
        })}
      </Swiper>
      <div>
        {React.Children.map(children, (child, index) => {
          if (child) {
            return (
              <div
                key={index}
                onClick={() => handleClick(index)}
                className={`pagination-dot ${activeIndex === index ? 'active' : ''}`}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
