import type { ClassNameProps } from '@/types/common.types';

const GoogleLogoSVG = ({ className }: ClassNameProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
      <path
        d="M21.5194 13.9999C21.5205 15.6516 20.9386 17.2507 19.8763 18.5155C18.814 19.7804 17.3395 20.6297 15.7124 20.9139C14.0853 21.1981 12.4101 20.899 10.9819 20.0693C9.55371 19.2395 8.46417 17.9323 7.90524 16.3781L3.89062 19.5897C5.13894 21.9627 7.14636 23.849 9.59233 24.9474C12.0383 26.0457 14.7818 26.2929 17.3847 25.6494C19.9876 25.0059 22.2998 23.5088 23.9522 21.3972C25.6046 19.2855 26.5018 16.6812 26.5005 13.9999"
        fill="#00AC47"
      />
      <path
        d="M21.5191 13.9999C21.5182 15.1166 21.2504 16.2169 20.7382 17.2091C20.2259 18.2014 19.484 19.0569 18.5742 19.7044L22.5432 22.8795C23.7877 21.759 24.7831 20.3896 25.4647 18.8599C26.1464 17.3303 26.4991 15.6745 26.5002 13.9999"
        fill="#4285F4"
      />
      <path
        d="M7.48113 13.9999C7.48287 13.1885 7.62621 12.3837 7.90471 11.6217L3.8901 8.41003C2.97727 10.1319 2.5 12.051 2.5 13.9999C2.5 15.9487 2.97727 17.8679 3.8901 19.5897L7.90471 16.378C7.62621 15.616 7.48287 14.8112 7.48113 13.9999Z"
        fill="#FFBA00"
      />
      <path
        d="M14.5005 6.98102C15.9857 6.98143 17.4319 7.45604 18.6285 8.3357L22.3055 4.90389C20.9406 3.72703 19.3275 2.87363 17.5866 2.40736C15.8457 1.9411 14.022 1.874 12.2516 2.21109C10.4811 2.54818 8.8097 3.28075 7.36197 4.35412C5.91424 5.42749 4.7276 6.81396 3.89062 8.41006L7.90524 11.6217C8.39485 10.2645 9.29055 9.09092 10.4706 8.26061C11.6506 7.43031 13.0577 6.98353 14.5005 6.98102Z"
        fill="#EA4435"
      />
      <path
        d="M26.5003 13.0942V13.9999L24.4626 17.1697H14.9531V12.1886H25.5946C25.8348 12.1886 26.0652 12.284 26.235 12.4538C26.4049 12.6237 26.5003 12.854 26.5003 13.0942Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default GoogleLogoSVG;
