import { useGoogleLogin } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel/GoogleLoginButton/useGoogleLogin';

export const GoogleLoginBase = props => {
  const {
    onSuccess,
    onAutoLoadFinished,
    onFailure,
    onScriptLoadFailure,
    render,
    disabled: disabledProp,
    clientId,
    cookiePolicy,
    loginHint,
    hostedDomain,
    autoLoad,
    isSignedIn,
    fetchBasicProfile,
    redirectUri,
    discoveryDocs,
    uxMode,
    scope,
    accessType,
    responseType,
    jsSrc,
    prompt,
  } = props;

  const { signIn, loaded } = useGoogleLogin({
    onSuccess,
    onAutoLoadFinished,
    onFailure,
    onScriptLoadFailure,
    clientId,
    cookiePolicy,
    loginHint,
    hostedDomain,
    autoLoad,
    isSignedIn,
    fetchBasicProfile,
    redirectUri,
    discoveryDocs,
    uxMode,
    scope,
    accessType,
    responseType,
    jsSrc,
    prompt,
  });

  const disabled = disabledProp || !loaded;

  if (render) {
    return render({ onClick: signIn, disabled });
  }

  return null;
};
