import { Fragment } from 'react';

import { useTranslations } from 'next-intl';

import { TransformedSubscription } from '@/api/types/user.types';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/WarningSubscription.module.scss';

// =================================================================

interface WarningSubscriptionProps {
  subscription: TransformedSubscription;
  message?: string;
}

// =================================================================

export const WarningSubscription = (props: WarningSubscriptionProps) => {
  const { subscription, message } = props;
  const t = useTranslations('verification');

  return (
    <Fragment>
      <p className={styles.dialogue}>{t('purchaseSubscriptionWarning')}</p>
      <div className={styles.optionDetails}>
        <p>
          <span>{t('tariff')}</span> : {subscription?.title}
        </p>
        <p>
          <span>{t('deadline')}</span> : {subscription?.days}
        </p>
        <p>
          <span>{t('price')}</span> {subscription?.priceStr}
        </p>
      </div>
      <p className={styles.warningText}>{t('operationIsIrreversible')}</p>
      <br />
      {message && <p className={styles.warningText}>{message}</p>}
    </Fragment>
  );
};
