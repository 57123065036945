import type { SVGProps } from '@/types/common.types';

const AppleDeviceSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M21.1312 1.33337C21.1312 1.33337 21.6816 3.89677 20.0566 5.55261C18.4316 7.20739 16.0456 7.43053 16.0456 7.43053C16.0456 7.43053 15.7927 4.91636 17.3204 3.32455C18.8493 1.73391 21.1312 1.33337 21.1312 1.33337Z"
        fill="currentColor"
      />
      <path
        d="M26.0749 10.4243C26.0749 10.4243 22.5708 12.5894 23.3044 16.2811C24.0379 19.9751 26.8736 20.8952 26.8736 20.8952C26.8736 20.8952 25.3447 25.0045 23.6557 26.4373C21.9712 27.8701 21.4585 28.2523 19.996 27.8403C19.5266 27.7082 19.1131 27.5402 18.7188 27.38C17.8799 27.0392 17.128 26.7337 16.1098 26.8847C15.4286 26.9869 14.8003 27.2336 14.2006 27.4691C13.4836 27.7507 12.8076 28.0162 12.1308 27.9994C10.8881 27.9673 9.13718 26.8539 7.41837 23.8911C5.69955 20.9306 4.80808 17.1108 5.22119 14.1171C5.6343 11.1246 7.92076 8.61727 10.0927 8.06914C12.0952 7.56253 13.3138 8.08448 14.3604 8.53279C14.9616 8.79032 15.5061 9.02353 16.1098 9.02353C16.1098 9.02353 16.6819 9.02353 17.8286 8.54517C17.9295 8.50313 18.0304 8.45987 18.1322 8.41625C19.1852 7.96477 20.3239 7.47658 22.4152 7.94097C24.7096 8.44909 26.0749 10.4243 26.0749 10.4243Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AppleDeviceSVG;
