import type { SVGProps } from '@/types/common.types';

const WebsiteSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_7470_302)">
        <path
          d="M22.0774 11.9974C20.0373 12.4607 18.1341 13.3958 16.5206 14.7275C14.9071 16.0593 13.6282 17.7507 12.7865 19.666H18.3872C19.0682 16.2247 20.391 13.4812 22.0774 11.9974Z"
          fill="currentColor"
        />
        <path
          d="M31.6104 19.666H37.2111C36.3696 17.7509 35.0909 16.0596 33.4777 14.7279C31.8646 13.3961 29.9617 12.461 27.9219 11.9974C29.6083 13.4812 30.9312 16.2247 31.6104 19.666Z"
          fill="currentColor"
        />
        <path
          d="M29.7873 19.6659C28.8494 15.3098 26.8491 12.5539 24.9991 12.5539C23.1491 12.5539 21.1488 15.3098 20.2109 19.6659H29.7873Z"
          fill="currentColor"
        />
        <path
          d="M19.6667 24.9999C19.6664 26.1892 19.7457 27.3772 19.904 28.5559H30.0974C30.2556 27.3772 30.3349 26.1892 30.3347 24.9999C30.3349 23.8106 30.2556 22.6226 30.0974 21.4439H19.904C19.7457 22.6226 19.6664 23.8106 19.6667 24.9999Z"
          fill="currentColor"
        />
        <path
          d="M24.9991 37.4461C23.1491 37.4461 21.1488 34.6901 20.2109 30.334H29.7873C28.8494 34.6901 26.8491 37.4461 24.9991 37.4461Z"
          fill="currentColor"
        />
        <path
          d="M31.8915 21.4439H37.8478C38.4962 23.7702 38.4953 26.2296 37.847 28.5559H31.8906C32.0376 27.3763 32.1112 26.1887 32.1111 24.9999C32.1115 23.8112 32.0382 22.6235 31.8915 21.4439Z"
          fill="currentColor"
        />
        <path
          d="M27.9219 38.0026C29.9621 37.5393 31.8652 36.6042 33.4787 35.2725C35.0923 33.9407 36.3712 32.2494 37.2129 30.334H31.6121C30.9312 33.7754 29.6083 36.5188 27.9219 38.0026Z"
          fill="currentColor"
        />
        <path
          d="M12.7917 30.334H18.3924C19.0716 33.7754 20.3944 36.5188 22.0827 38.0026C20.0425 37.5393 18.1393 36.6042 16.5258 35.2725C14.9123 33.9407 13.6334 32.2494 12.7917 30.334Z"
          fill="currentColor"
        />
        <path
          d="M18.1066 21.4439C17.9597 22.6235 17.886 23.8111 17.8862 24.9999C17.886 26.1887 17.9597 27.3763 18.1066 28.5559H12.1503C11.502 26.2296 11.502 23.7702 12.1503 21.4439H18.1066Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default WebsiteSVG;
