import cn from 'classnames';

import { ClassNameProps } from '@/types/common.types';

import styles from '@/components/Spinner/Spinner.module.scss';

// =================================================================

type SpinnerProps = ClassNameProps & {
  variant?: 'light' | 'signature';
  width?: string;
  height?: string;
};

// =================================================================

export const Spinner = (props: SpinnerProps) => {
  const { variant = 'light', width = '1rem', height = '1rem', className } = props;

  return (
    <div className={cn(className)}>
      <div
        style={{ width, height }}
        className={cn({
          [styles.spinnerBase]: true,
          [styles[variant]]: Boolean(variant),
        })}
      />
    </div>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Spinner.displayName = 'Spinner';
}
