'use client';

import { Fragment } from 'react';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { useStore } from '@/contexts/StoreContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';
import { AuthVerificationHeader } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerificationHeader';
import { AuthVerificationTabs } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerificationTabs';
import { LoginWithPhone } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithPhone';
import { LoginWithUsername } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithUsername';
import { SocialLoginPanel } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel';

export const AuthVerification = () => {
  const { isLoginWithPhone } = useStore(store => store.verificationStore);
  const { isQRCodeVerification, hasOtp } = useVerification();

  return (
    <div className={styles.authVerificationWrapper}>
      {!isQRCodeVerification ? (
        <Fragment>
          <header>
            <AuthVerificationHeader />
            {!hasOtp && <AuthVerificationTabs />}
          </header>
          <main>{isLoginWithPhone ? <LoginWithPhone /> : <LoginWithUsername />}</main>
          <footer>
            <SocialLoginPanel />
          </footer>
        </Fragment>
      ) : (
        <>QR</>
      )}
    </div>
  );
};
