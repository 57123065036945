'use client';

import { createContext, useRef, useContext } from 'react';

import { type StoreApi, useStore as useZustandStore } from 'zustand';

import { ChildrenProps } from '@/types/common.types';

import { type BoundStore, createBoundStore } from '@/zustand-store';

// =================================================================

export const StoreContext = createContext<StoreApi<BoundStore> | null>(null);

// =================================================================

export const StoreProvider = (props: ChildrenProps) => {
  const { children } = props;

  const storeRef = useRef<StoreApi<BoundStore>>();
  if (!storeRef.current) {
    storeRef.current = createBoundStore;
  }

  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
};

// =================================================================

export const useStore = <T extends any>(selector: (store: BoundStore) => T): T => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error('useStore should be used within StoreProvider');
  }

  return useZustandStore(context, selector);
};
