/* eslint-disable @next/next/no-img-element */
import { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { TransformedSubscription } from '@/api/types/user.types';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SelectedSubscriptionInfo.module.scss';

// =================================================================

interface SelectedSubscriptionInfoProps {
  subscription: TransformedSubscription | null;
}

// =================================================================

export const SelectedSubscriptionInfo = (props: SelectedSubscriptionInfoProps) => {
  const { subscription } = props;

  return (
    <Fragment>
      <div className={styles.subscriptionInfo}>
        <ul className={styles.limitList}>
          {subscription?.tags?.map((limit, key) => <li key={key}>{limit}</li>)}
        </ul>

        {subscription?.description && (
          <p className={styles.description}>{subscription?.description}</p>
        )}

        {subscription?.icons && (
          <ul className={styles.icons}>
            {subscription?.icons?.map(icon => (
              <li key={icon.alt}>
                <img src={icon.src} alt={icon.alt} />
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className={styles.lineRow} />
    </Fragment>
  );
};

// =================================================================

export const SelectedSubscriptionInfoPlaceholder = () => {
  return (
    <div className={styles.placeholderWrapper}>
      <SkeletonTheme baseColor="#000" highlightColor="#444">
        <Skeleton count={2} />
      </SkeletonTheme>
    </div>
  );
};
