interface calculatePaymentConfig {
  minCardSum: number;
  subscriptionPrice: number;
  balance: number;
  forcePayWithCard: boolean;
}

export const calculatePayment = (
  config: calculatePaymentConfig,
): {
  fromCard: number;
  fromBalance: number;
} => {
  const { minCardSum, subscriptionPrice, balance, forcePayWithCard } = config;

  const result = {
    fromCard: 0,
    fromBalance: 0,
  };

  if (forcePayWithCard) {
    result.fromCard = subscriptionPrice;
  } else {
    if (balance >= subscriptionPrice) {
      result.fromBalance = subscriptionPrice;
    } else {
      result.fromBalance = balance;
      result.fromCard = subscriptionPrice - balance;

      if (result.fromCard < minCardSum) {
        result.fromCard = minCardSum;
        result.fromBalance = subscriptionPrice - minCardSum;
      }
    }
  }

  return result;
};
