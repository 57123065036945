import { Fragment } from 'react';

import { useTranslations } from 'next-intl';

// import QRCodeSVG from '@/icons/QRCodeSVG';
// import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { GoogleLoginButton } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel/GoogleLoginButton';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/SocialLoginPanel/SocialLoginPanel.module.scss';

export const SocialLoginPanel = () => {
  const t = useTranslations('verification');
  // const { setIsQRCodeVerification } = useVerification();

  return (
    <Fragment>
      <p className={styles.socialGroupTitle}>
        <span>{t('orLoginUsing')}</span>
      </p>

      <div className={styles.buttons}>
        <GoogleLoginButton />
        {/* <button onClick={() => setIsQRCodeVerification(true)} className={styles.socialButton}>
          <QRCodeSVG />
        </button> */}
      </div>
    </Fragment>
  );
};
