import { ChangeEvent } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import CheckedSVG from '@/icons/CheckedSVG';

import { TransformedSubscription } from '@/api/types/user.types';

import { normalizePrise } from '@/helpers/transformations.helpers';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionCard.module.scss';

// =================================================================

interface SubscriptionCardProps {
  subscription: TransformedSubscription;
  onPurchaseStart: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  checked: boolean;
  value: string | number;
}

// =================================================================

export const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { subscription, name, onPurchaseStart, checked, ...rest } = props;
  const t = useTranslations('verification');

  const { price, days, title, oldPrice } = subscription;

  return (
    <button type="button" className={styles.subscriptionWrapper}>
      <label className={clsx(styles.subscriptionCard, checked && styles.active)}>
        <input
          type="radio"
          className={styles.inputRadio}
          onChange={event => {
            onPurchaseStart(event);
          }}
          name={name}
          id={name}
          hidden
          checked={checked}
          {...rest}
        />
        <span className={styles.check}></span>
        <CheckedSVG className={styles.checkedIcon} />
        <div>
          <h2 className={styles.title}>{title}</h2>
          {oldPrice && (
            <div className={styles.deletedPrice}>
              {t('price', {
                price: normalizePrise(oldPrice),
              })}
            </div>
          )}

          <div className={styles.price}>
            <span className={styles.priceStr}>
              {t('price', {
                price: normalizePrise(price),
              })}
            </span>
            /
            <span className={styles.days}>
              {t('days', {
                days,
              })}
            </span>
          </div>
        </div>
      </label>
    </button>
  );
};

// =================================================================

export const SubscriptionPlaceholderCard = () => {
  return (
    <div className={styles.placeholderWrapper}>
      <SkeletonTheme baseColor="#000" highlightColor="#444">
        <Skeleton className={styles.placeholderCard} />
      </SkeletonTheme>
    </div>
  );
};
