import { create } from 'zustand';

import { createHeaderSlice, type HeaderStore } from '@/zustand-store/slices/header-slice';
import {
  createSubscriptionSlice,
  type SubscriptionStore,
} from '@/zustand-store/slices/subscription-slice';
import {
  type VerificationStore,
  createVerificationSlice,
} from '@/zustand-store/slices/verification-slice';

// ----------------------------------------------------------------

export type BoundStore = HeaderStore & VerificationStore & SubscriptionStore;

// ----------------------------------------------------------------

export const createBoundStore = create<BoundStore>()((...a) => ({
  ...createHeaderSlice(...a),
  ...createVerificationSlice(...a),
  ...createSubscriptionSlice(...a),
}));
