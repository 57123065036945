'use client';

import React, { useState } from 'react';

import { CardConfirmation } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/CardConfirmation';
import { PaymentWithCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/PaymentWithCard';
import BankCardProvider from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/contexts/BankCardProvider';

export const UserPayments = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [hasOTP, setHasOTP] = useState(false);
  const [data, setData] = useState(null);

  return (
    <BankCardProvider
      responseMessage={responseMessage}
      setResponseMessage={setResponseMessage}
      data={data}
      hasOTP={hasOTP}
      setHasOTP={setHasOTP}
      setData={setData}
    >
      {!hasOTP ? <PaymentWithCard /> : <CardConfirmation />}
    </BankCardProvider>
  );
};
