import {
  Swiper,
  SwiperSlide,
  SwiperRef,
  SwiperProps,
  SwiperSlideProps,
  useSwiper,
  SwiperClass,
} from 'swiper/react';

import SwiperCore from 'swiper';
import { Autoplay, Navigation, Mousewheel } from 'swiper/modules';
import { NavigationOptions } from 'swiper/types';

// =================================================================

SwiperCore.use([Autoplay, Navigation, Mousewheel]);

export { Swiper, SwiperSlide, useSwiper };
export type { SwiperRef, SwiperProps, SwiperSlideProps, NavigationOptions, SwiperClass };
