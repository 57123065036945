import type { ClassNameProps } from '@/types/common.types';

const KeySVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.0119 1.99107C13.3481 -0.663689 9.0285 -0.663689 6.38274 1.99107C4.51991 3.8359 3.96197 6.49066 4.6819 8.83044L0.452287 13.0601C0.155315 13.366 -0.0516663 13.969 0.0113279 14.4009L0.281303 16.3628C0.380294 17.0107 0.983239 17.6226 1.63118 17.7126L3.593 17.9826C4.02496 18.0456 4.6279 17.8476 4.93388 17.5326L5.67181 16.7947C5.85179 16.6237 5.85179 16.3358 5.67181 16.1558L3.92597 14.4099C3.66499 14.149 3.66499 13.717 3.92597 13.456C4.18695 13.195 4.61891 13.195 4.87988 13.456L6.63472 15.2109C6.8057 15.3818 7.09368 15.3818 7.26466 15.2109L9.17249 13.312C11.5033 14.041 14.158 13.474 16.0119 11.6292C18.6666 8.97443 18.6666 4.64582 16.0119 1.99107ZM11.2513 8.99243C10.0094 8.99243 9.0015 7.98452 9.0015 6.74263C9.0015 5.50075 10.0094 4.49284 11.2513 4.49284C12.4932 4.49284 13.5011 5.50075 13.5011 6.74263C13.5011 7.98452 12.4932 8.99243 11.2513 8.99243Z"
        fill="white"
      />
    </svg>
  );
};

export default KeySVG;
