import DoorSVG from '@/icons/DoorSVG';
import { MailOpenedSVG } from '@/icons/MailOpenedSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerificationHeader.module.scss';

export const AuthVerificationHeader = () => {
  const { hasOtp } = useVerification();

  return (
    <div className={styles.authVerificationHeader}>
      <h1 className={styles.authVerificationIcon}>{hasOtp ? <MailOpenedSVG /> : <DoorSVG />}</h1>
      <h1 className={styles.authVerificationTitle}>
        {hasOtp ? 'Введите код' : 'Войдите в систему'}
      </h1>
      {hasOtp && (
        <p className={styles.authVerificationSubtitle}>
          Введите 6-значный код подтверждения отправленный на введённый номер телефона
        </p>
      )}
    </div>
  );
};
