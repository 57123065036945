'use client';

import { ReactNode, createContext, useContext } from 'react';
import { ToastContainer, ToastOptions, toast } from 'react-toastify';

import clsx from 'clsx';

import CloseSVG from '@/icons/CloseSVG';
import ErrorSVG from '@/icons/ErrorSVG';
import SuccessSVG from '@/icons/SuccessSVG';

import { Button } from '@/components/Buttons';

import styles from '@/contexts/NotificationContext/Notification.module.scss';

import 'react-toastify/dist/ReactToastify.css';

// =================================================================

type NotificationProviderProps = {
  children: ReactNode;
};

type NotificationType = 'error' | 'success';

type NotificationContextState = {
  notification: (type: NotificationType, message?: string, options?: ToastOptions<unknown>) => void;
};

// =================================================================

const NotificationContext = createContext<NotificationContextState | null>(null);

// =================================================================

const ToastIcons = {
  success: SuccessSVG,
  error: ErrorSVG,
};

const notification = (
  type: NotificationType,
  message?: string,
  options?: ToastOptions<unknown>,
) => {
  const classes = {
    className: clsx(styles.notification, styles[type]),
    bodyClassName: styles.customToastBody,
    toastClassName: styles.customtoast,
  };

  const Icon = ToastIcons[type];

  toast[type](message, {
    ...classes,
    ...options,
    icon: (
      <Icon
        className={clsx(
          styles.iconWrapper,
          type === 'error' ? styles.errorIcon : styles.successIcon,
        )}
      />
    ),
  });
};

// =================================================================

export const NotificationProvider = (props: NotificationProviderProps) => {
  const { children } = props;

  return (
    <NotificationContext.Provider value={{ notification }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={
          <Button as="button" size="md" variant="secondary" shape="circle">
            <CloseSVG width={25} height={25} />
          </Button>
        }
        hideProgressBar
        theme="dark"
      />
    </NotificationContext.Provider>
  );
};

// =================================================================

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification should be used within NotificationProvider');
  }

  return context;
};
