import parseHtml from 'html-react-parser';

import { TransformedSubscription } from '@/api/types/user.types';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionInfoCard.module.scss';

// =================================================================

export const SubscriptionInfoCard = (props: Pick<TransformedSubscription, 'notes'>) => {
  const { notes } = props;

  return (
    <ul className={styles.subscriptionInfoCard}>
      {notes?.map((item, index) => (
        <li key={index}>
          <div>{parseHtml(item)}</div>
        </li>
      ))}
    </ul>
  );
};
