import { isBrowserStorageSupported } from '@/helpers/supports.helpers';

// ----------------------------------------------------------------------

export type StorageValue = object | string | number | boolean | null;

type CustomStorageInterface = {
  getItem: <Value extends StorageValue>(
    key: string,
    fallback?: Value | (() => Value),
  ) => typeof fallback extends undefined ? Value | null : Value;
  setItem: <Value>(key: string, value: Value) => void;
  removeItem: (key: string) => void;
  clear: () => void;
  length: number;
};

type CustomStorageOptions = {
  serializer?: (value: any) => string;
  deserializer?: <T>(value: string) => T;
};

// ----------------------------------------------------------------------

class FallbackStorage implements Storage {
  _store: Record<string, string> = {};

  getItem(key: string) {
    return this._store[key] || null;
  }

  setItem(key: string, value: string) {
    this._store[key] = value;
  }

  removeItem(key: string) {
    delete this._store[key];
  }

  clear() {
    this._store = {};
  }

  key(index: number) {
    return Object.keys(this._store)[index] || null;
  }

  get length() {
    return Object.keys(this._store).length;
  }
}

// ----------------------------------------------------------------------

export class CustomStorage implements CustomStorageInterface {
  private storage: Storage = new FallbackStorage();

  private readonly options: Required<CustomStorageOptions> = {
    serializer: (value: any) => {
      try {
        return JSON.stringify(value);
      } catch {
        return String(value);
      }
    },
    deserializer: (value: string) => {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    },
  };

  constructor(type: 'local' | 'session', options?: CustomStorageOptions) {
    if (isBrowserStorageSupported) {
      this.storage = type === 'local' ? localStorage : sessionStorage;
    }

    this.options = { ...this.options, ...options };
  }

  get length() {
    return this.storage.length;
  }

  clear() {
    this.storage.clear();
  }

  getItem<Value extends StorageValue>(
    key: string,
    fallback?: (() => Value) | Value,
  ): typeof fallback extends undefined ? Value | null : Value {
    const dirtyValue = this.storage.getItem(key);

    if (dirtyValue === null) {
      return typeof fallback === 'function' ? fallback() : fallback ?? null;
    }

    return this.options.deserializer(dirtyValue);
  }

  removeItem(key: string) {
    this.storage.removeItem(key);

    return undefined;
  }

  setItem<Value>(key: string, value: Value) {
    this.storage.setItem(key, this.options.serializer(value));
  }
}

// ----------------------------------------------------------------------

export const customLocalStorage = new CustomStorage('local');
export const customSessionStorage = new CustomStorage('session');
