'use client';

import { useMemo } from 'react';

import { useLocale } from 'next-intl';

import { usePathname } from '@/navigation';
import type { Locale } from '@/types/common.types';

import { useShowcasedSubscriptions } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/hooks/useShowcasedSubscriptions';
import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { TransformedSubscription } from '@/api/types/user.types';

import { SubscriptionRow } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionRow';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionVerification.module.scss';
import SubscriptionVerificationProvider from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts/SubscriptionVerificationProvider';

// =================================================================

const PROFILE_BASE_URL = '/user-profile';
const SUBSCRIPTIONS_BASE_URL = '/subscriptions';

// =================================================================

export const SubscriptionVerification = () => {
  const locale = useLocale() as Locale;

  const isUz = locale === 'uz';

  const pathname = usePathname();

  const { subscription, loadingSubscription } = useShowcasedSubscriptions();

  const {
    userCredentials,
    subscriptionTitle,
    subscription: selectedsubscription,
    selectToBuySubscription,
  } = useVerification();

  const isProfilePage = useMemo(() => {
    return pathname.startsWith(PROFILE_BASE_URL) || pathname === SUBSCRIPTIONS_BASE_URL;
  }, [pathname]);

  // TODO : this title should be sent by the backend
  const title = !isProfilePage
    ? `${subscriptionTitle} ${selectedsubscription?.title || ''} ${isUz ? 'obunasi bilan tomosha qiling' : ''}`
    : undefined;

  return (
    <SubscriptionVerificationProvider userCredentials={userCredentials!}>
      <div className={styles.subscriptionModalBody}>
        <SubscriptionRow
          subscriptions={
            isProfilePage
              ? (Array(selectToBuySubscription) as TransformedSubscription[])
              : subscription
          }
          loadingSubscription={isProfilePage ? false : loadingSubscription}
          title={title}
        />
      </div>
    </SubscriptionVerificationProvider>
  );
};
