/**
 * Incognito mode might reject access to the localStorage for security reasons.
 * {@link http://stackoverflow.com/questions/16427636/check-if-localstorage-is-available}
 */
export const isBrowserStorageSupported = (() => {
  try {
    localStorage.setItem('KEY_FOR_TEST_LOCAL_STORAGE', 'test');
    localStorage.removeItem('KEY_FOR_TEST_LOCAL_STORAGE');
    return true;
  } catch (e) {
    return false;
  }
})();

// ----------------------------------------------------------------------

export const isWebAnimationsSupported = (() => {
  try {
    return 'animate' in document.createElement('div');
  } catch (e) {
    return false;
  }
})();
