'use client';

import { useState, JSX, Fragment, useCallback, useMemo, ElementType } from 'react';

import clsx from 'clsx';

import type { SessionList } from '@/app/actions/auth/types';
import AndroidDeviceSVG from '@/icons/AndroidDeviceSVG';
import AppleDeviceSVG from '@/icons/AppleDeviceSVG';
import SmartTvDeviceSVG from '@/icons/SmartTvDeviceSVG';
import TrashbinSVG from '@/icons/TrashbinSVG';
import WebsiteSVG from '@/icons/WebsiteSVG';

import { UserApi } from '@/api/domains/user-api';

import { HTTP_CODE_SUCCESS } from '@/constants/http-code.constants';

import { DeviceSessionEnum } from '@/enums/device-session.enums';

import { Alert } from '@/components/Alert';
import { Button } from '@/components/Buttons';
import styles from '@/components/SessionCard/SessionCard.module.scss';
import { Spinner } from '@/components/Spinner';
import { TruncatedText } from '@/components/TruncatedText';

import { useNotification } from '@/contexts/NotificationContext';

// =================================================================

type SessionCardProps = {
  as?: ElementType;
  session: SessionList;
  onSessionDelete?: () => void;
  className?: string;
  hasTerminateButton?: boolean;
};

const deviceIcons: Record<DeviceSessionEnum, JSX.Element> = {
  'android-mobile': <AndroidDeviceSVG />,
  'apple-ios': <AppleDeviceSVG />,
  tv: <SmartTvDeviceSVG />,
  'android-tv': <AndroidDeviceSVG />,
  'apple-tv': <AppleDeviceSVG />,
  'web-site': <WebsiteSVG />,
  tizen: <SmartTvDeviceSVG />,
};

// =================================================================

export const SessionCard = (props: SessionCardProps) => {
  const {
    session,
    onSessionDelete,
    className,
    as: Element = 'button',
    hasTerminateButton = false,
  } = props;
  const { deviceBrand, deviceModel, loginDate, isCurrent, sessionId, apiType, ipAddress } = session;

  const { notification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const title = `${deviceBrand}${deviceModel ? `, ${deviceModel}` : ''}`;
  const dateArr = loginDate.replaceAll('-', '.').split(' ');
  const transformedDate = dateArr[1] + ' ' + dateArr[0].substring(0, 5);

  const modalInfoList = useMemo(() => {
    return [
      {
        label: 'IP-адрес',
        value: session.ipAddress,
      },
      {
        label: 'Операционная система',
        value: session.deviceBrand,
      },
      {
        label: 'Имя устройства',
        value: session.deviceModel,
      },
      {
        label: 'Версия приложения',
        value: session.deviceVersion,
      },
      {
        label: 'Дата входа в приложение',
        value: session.loginDate,
      },
    ];
  }, [session]);

  const handleOpenConfirmationAlert = useCallback(() => {
    if (!isCurrent) {
      setIsOpen(true);
    }
  }, [isCurrent]);

  const handleCloseConfirmationAlert = useCallback(() => {
    if (!isLoading) {
      setIsOpen(false);
    }
  }, [isLoading]);

  const deleteSession = async () => {
    setIsLoading(true);
    try {
      const response = await UserApi.deleteSession({ sessionId });

      if (response.code === HTTP_CODE_SUCCESS) {
        onSessionDelete?.();
        notification('success', 'Session deleted successfully');
      }
    } catch (error) {
      if (!error.resonse) {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const elementProps =
    Element === 'button'
      ? { title, ['aria-label']: title, type: 'button', onClick: handleOpenConfirmationAlert }
      : {
          title,
          ['aria-label']: title,
        };

  return (
    <Fragment>
      <Element
        {...elementProps}
        className={clsx(styles.sessionCard, isCurrent && styles.currentSession, className)}
      >
        <div className={clsx(styles.iconWrapper, isCurrent && styles.currentSessionIcon)}>
          {deviceIcons[apiType]}
        </div>
        <div className={styles.infoWrapper}>
          <TruncatedText as="h3" lines={1} className={styles.title}>
            {title}
          </TruncatedText>
          <div className={styles.dateWrapper}>
            <p className={styles.sessionDate}>{ipAddress}</p>
            <p className={styles.sessionDate}>{transformedDate}</p>
          </div>
          {hasTerminateButton && (
            <button
              aria-label="terminate session button"
              title="terminate session button"
              type="button"
              onClick={deleteSession}
              className={styles.terminateButtton}
            >
              {isLoading ? <Spinner /> : <TrashbinSVG />}
            </button>
          )}
        </div>
      </Element>
      <Alert
        isOpen={isOpen}
        size="sm"
        title={title}
        icon={deviceIcons[apiType]}
        footer={
          <Button
            size="lg"
            disabled={isLoading}
            as="button"
            variant="secondary-red"
            onClick={deleteSession}
          >
            Выйти из устройства
          </Button>
        }
        iconWrapperClassName={clsx(styles.iconWrapper, styles.alertIcon, styles.currentSessionIcon)}
        onClose={handleCloseConfirmationAlert}
      >
        <ul className={styles.sessionsInfoWrapper}>
          {modalInfoList.map((item, index) => (
            <li key={index}>
              <p className={styles.infoLabel}>{item.label}</p>
              <p className={styles.labelValue}>{item.value}</p>
            </li>
          ))}
        </ul>
      </Alert>
    </Fragment>
  );
};
