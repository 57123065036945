export const AppleStoreIconSVG = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5773 12.7804C19.5883 11.9262 19.8152 11.0886 20.2369 10.3457C20.6585 9.60273 21.2613 8.97851 21.989 8.53111C21.5267 7.87084 20.9168 7.32747 20.2078 6.94415C19.4987 6.56083 18.7101 6.34812 17.9044 6.32292C16.1859 6.14253 14.5198 7.35127 13.6441 7.35127C12.7514 7.35127 11.403 6.34083 9.95111 6.3707C9.01195 6.40104 8.09667 6.67414 7.29444 7.16339C6.49222 7.65264 5.8304 8.34135 5.37348 9.16242C3.39421 12.5892 4.87057 17.6255 6.76655 20.3954C7.71514 21.7518 8.82378 23.2669 10.2744 23.2132C11.6939 23.1543 12.2241 22.308 13.9376 22.308C15.6352 22.308 16.1326 23.2132 17.6127 23.179C19.1359 23.1543 20.0956 21.8166 21.0109 20.4474C21.6925 19.4809 22.2169 18.4128 22.5649 17.2825C21.6799 16.9082 20.9247 16.2817 20.3934 15.4811C19.8622 14.6805 19.5783 13.7412 19.5773 12.7804Z"
        fill="white"
      />
      <path
        d="M16.7815 4.50104C17.6121 3.50403 18.0212 2.22254 17.9221 0.928711C16.6533 1.06198 15.4812 1.66841 14.6395 2.62717C14.2279 3.09555 13.9127 3.64045 13.7119 4.23072C13.511 4.82099 13.4285 5.44506 13.469 6.06724C14.1037 6.07378 14.7315 5.93622 15.3053 5.66493C15.8791 5.39363 16.3838 4.99568 16.7815 4.50104Z"
        fill="white"
      />
    </svg>
  );
};
