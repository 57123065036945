'use client';

import { ReactNode, type JSX } from 'react';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import DoorSVG from '@/icons/DoorSVG';
import LogoutSVG from '@/icons/LogoutSVG';
import { ChildrenProps } from '@/types/common.types';

import styles from '@/components/Alert/Alert.module.scss';
import { Modal, type ModalProps } from '@/components/Modal';

// =================================================================

export interface AlertProps extends Partial<ChildrenProps>, Omit<ModalProps, 'children'> {
  title?: string;
  descritption?: string;
  footer?: ReactNode;
  status?: 'error' | 'warning' | 'success';
  iconWrapperClassName?: string;
  icon?: JSX.Element;
}

// =================================================================

export const Alert = (props: AlertProps) => {
  const {
    title,
    descritption,
    footer,
    children,
    onClose,
    iconWrapperClassName,
    status = 'error',
    icon = <LogoutSVG width={40} height={40} />,
    ...rest
  } = props;

  const t = useTranslations('common');

  const iconWrapperDefaultClassName = iconWrapperClassName || clsx(styles.status, styles[status]);

  return (
    <Modal {...rest} onClose={onClose} className={styles.alertModal}>
      <Modal.Header onClose={onClose} />
      <Modal.Body>
        {icon && <div className={iconWrapperDefaultClassName}>{icon}</div>}
        <h2 className={styles.title}>{title || t('attention')}</h2>
        {descritption && <p className={styles.descritption}>{descritption}</p>}
        {children}
      </Modal.Body>
      {footer && <Modal.Footer className={styles.alertFooter}>{footer}</Modal.Footer>}
    </Modal>
  );
};
