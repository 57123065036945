import type { SVGProps } from '@/types/common.types';

const SmartTvDeviceSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M18.6045 18.0293L14.9137 16.1851C14.8712 16.1633 14.8242 16.1515 14.7764 16.1508C14.7196 16.1511 14.664 16.1672 14.6159 16.1974C14.5708 16.2243 14.5336 16.2626 14.508 16.3084C14.4824 16.3542 14.4693 16.4059 14.4701 16.4584V20.7631C14.47 20.8186 14.4851 20.8731 14.5136 20.9207C14.5421 20.9683 14.583 21.0073 14.632 21.0335C14.6809 21.0597 14.7361 21.0721 14.7915 21.0693C14.847 21.0666 14.9006 21.0488 14.9467 21.018L18.6363 18.5587C18.6808 18.5288 18.7167 18.4878 18.7405 18.4398C18.7642 18.3918 18.7751 18.3384 18.7718 18.2849C18.7686 18.2314 18.7515 18.1797 18.7221 18.1349C18.6928 18.09 18.6522 18.0536 18.6045 18.0293Z"
        fill="url(#paint0_linear_7291_11983)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6543 7.88945L21.7703 4.77338C21.8874 4.66034 21.9807 4.52513 22.0449 4.37563C22.1092 4.22613 22.143 4.06534 22.1444 3.90264C22.1458 3.73993 22.1148 3.57858 22.0532 3.42799C21.9916 3.27739 21.9006 3.14058 21.7855 3.02553C21.6705 2.91047 21.5337 2.81949 21.3831 2.75787C21.2325 2.69626 21.0711 2.66526 20.9084 2.66667C20.7457 2.66809 20.5849 2.70189 20.4354 2.76611C20.2859 2.83033 20.1507 2.92368 20.0377 3.04071L16.0025 7.07583L11.9674 3.04071C11.8544 2.92368 11.7192 2.83033 11.5697 2.76611C11.4202 2.70189 11.2594 2.66809 11.0967 2.66667C10.934 2.66526 10.7726 2.69626 10.622 2.75787C10.4714 2.81949 10.3346 2.91047 10.2196 3.02553C10.1045 3.14058 10.0135 3.27739 9.95193 3.42799C9.89031 3.57858 9.85931 3.73993 9.86072 3.90264C9.86214 4.06534 9.89594 4.22613 9.96016 4.37563C10.0244 4.52513 10.1177 4.66034 10.2348 4.77338L13.3508 7.88945H8.03652C6.81822 7.89074 5.65019 8.37529 4.78872 9.23675C3.92725 10.0982 3.4427 11.2663 3.44141 12.4846V24.7382C3.4427 25.9565 3.92725 27.1245 4.78872 27.986C5.65019 28.8475 6.81822 29.332 8.03652 29.3333H23.9662C25.1845 29.332 26.3526 28.8475 27.214 27.986C28.0755 27.1245 28.56 25.9565 28.5613 24.7382V12.4846C28.56 11.2663 28.0755 10.0982 27.214 9.23675C26.3526 8.37529 25.1845 7.89074 23.9662 7.88945H18.6543ZM15.9658 22.5472L19.6566 20.0879C19.969 19.8795 20.2212 19.5926 20.3879 19.2561C20.5546 18.9196 20.63 18.5452 20.6065 18.1704C20.5831 17.7955 20.4616 17.4334 20.2543 17.1203C20.0469 16.8072 19.761 16.554 19.425 16.3861L15.7355 14.5407C15.4085 14.3772 15.0452 14.2999 14.68 14.3162C14.3148 14.3326 13.9599 14.4421 13.6489 14.6342C13.3379 14.8263 13.0812 15.0948 12.9032 15.4141C12.7251 15.7334 12.6316 16.0928 12.6316 16.4584V20.7631C12.6312 21.1515 12.7363 21.5326 12.9357 21.8659C13.1351 22.1991 13.4212 22.4719 13.7636 22.6552C14.1061 22.8384 14.4918 22.9251 14.8797 22.9061C15.2676 22.8871 15.643 22.7631 15.9658 22.5472Z"
        fill="url(#paint1_linear_7291_11983)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7291_11983"
          x1="3.11184"
          y1="-9.15156"
          x2="-29.1975"
          y2="64.0141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7291_11983"
          x1="3.11184"
          y1="-9.15156"
          x2="-29.1975"
          y2="64.0141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFF4D" />
          <stop offset="1" stopColor="#F46600" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SmartTvDeviceSVG;
