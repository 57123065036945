import type { SVGProps } from '@/types/common.types';

const SuccessSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.268 17.3409C9.21759 17.3409 9.16772 17.3305 9.12153 17.3102C9.07534 17.29 9.03383 17.2605 8.99961 17.2235L1.76312 9.39566C1.71488 9.34347 1.6829 9.27835 1.67109 9.20827C1.65929 9.13819 1.66817 9.06619 1.69665 9.00108C1.72513 8.93597 1.77197 8.88057 1.83144 8.84167C1.89092 8.80277 1.96044 8.78205 2.03151 8.78205H5.51476C5.56706 8.78206 5.61874 8.79328 5.66634 8.81496C5.71393 8.83665 5.75632 8.86829 5.79064 8.90775L8.2091 11.6901C8.47047 11.1314 8.97644 10.2011 9.86432 9.06754C11.1769 7.39171 13.6184 4.92709 17.7955 2.70219C17.8763 2.6592 17.9702 2.64804 18.0587 2.67092C18.1473 2.6938 18.2241 2.74906 18.2739 2.82578C18.3237 2.9025 18.3429 2.9951 18.3278 3.08531C18.3126 3.17552 18.2642 3.25677 18.1921 3.31302C18.1762 3.32549 16.5656 4.59378 14.7121 6.91689C13.0062 9.05471 10.7385 12.5504 9.62269 17.0633C9.60309 17.1425 9.55749 17.213 9.49319 17.2633C9.42888 17.3137 9.34956 17.341 9.26789 17.341L9.268 17.3409Z"
        fill="white"
      />
    </svg>
  );
};

export default SuccessSVG;
