import type { SVGProps } from '@/types/common.types';

const AndroidDeviceSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <path
        d="M7.85938 20.6913C7.85938 25.5061 11.6865 29.3333 16.5014 29.3333C21.3162 29.3333 25.1433 25.5061 25.1433 20.6913V15.753H7.85938V20.6913ZM21.5631 6.37033L24.1557 3.77774L23.168 2.79008L20.3285 5.62959C19.2174 5.01231 17.8594 4.64193 16.5014 4.64193C15.1433 4.64193 13.7853 5.01231 12.6742 5.50613L9.83468 2.66663L8.84703 3.77774L11.4396 6.37033C9.2174 7.85181 7.85938 10.4444 7.85938 13.2839V14.5185H25.1433V13.2839C25.1433 10.4444 23.7853 7.85181 21.5631 6.37033ZM12.7976 12.0493C12.0569 12.0493 11.5631 11.5555 11.5631 10.8148C11.5631 10.074 12.0569 9.58021 12.7976 9.58021C13.5384 9.58021 14.0322 10.074 14.0322 10.8148C14.0322 11.5555 13.5384 12.0493 12.7976 12.0493ZM20.2051 12.0493C19.4643 12.0493 18.9705 11.5555 18.9705 10.8148C18.9705 10.074 19.4643 9.58021 20.2051 9.58021C20.9458 9.58021 21.4396 10.074 21.4396 10.8148C21.4396 11.5555 20.9458 12.0493 20.2051 12.0493Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AndroidDeviceSVG;
