'use client';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { confirmOtp, loginWithPhone } from '@/app/actions/auth';
import type { SessionList, SessionsReachedResponse } from '@/app/actions/auth/types';
import EditSVG from '@/icons/EditSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';
import type { ErrorResponse } from '@/api/types/common.types';

import { normalizePhone } from '@/helpers/transformations.helpers';

import {
  HTTP_CODE_REACHED_SESSIONS_LIMIT,
  HTTP_CODE_SUCCESS,
} from '@/constants/http-code.constants';

import { Button, TextButton } from '@/components/Buttons';
import { Form, FormAlertType } from '@/components/Form';
import { SessionsModal } from '@/components/SessionsModal';

import { useNotification } from '@/contexts/NotificationContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';

// =================================================================

type SmsFormType = {
  code: string;
};

// =================================================================

const MAX_LENGTH = 6;

// =================================================================

export const SmsForm = () => {
  const t = useTranslations();
  const { notification } = useNotification();

  const { phone, setOtpStatus, setAccessToken, setUserCredentials, authCallbackOnSuccess } =
    useVerification();

  const [timer, setTimer] = useState(59);
  const [sessions, setSessions] = useState<SessionList[]>([]);
  const [code, setCode] = useState('');

  const schema = useMemo(
    () =>
      yup
        .object({
          code: yup
            .string()
            .trim()
            .matches(/[0-9]{5}/, t('formWarningMassage.invalidCode'))
            .required(t('formWarningMassage.requiredField'))
            .max(MAX_LENGTH, t('formWarningMassage.maxLengthExceeded', { maxLength: MAX_LENGTH })),
        })
        .required(),
    [t],
  );

  const methods = useForm<SmsFormType>({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer) {
        setTimer(timer => timer - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const onSubmit: SubmitHandler<SmsFormType> = async data => {
    const { code } = data;
    setCode(code);

    const response = await UserApi.confirmOtp({ phone, code });

    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data;
      setAccessToken(cineramaToken);
      setUserCredentials(me);

      authCallbackOnSuccess();
    } else if (response.code === HTTP_CODE_REACHED_SESSIONS_LIMIT) {
      const { sessionList } = response.data as unknown as SessionsReachedResponse;
      setSessions(sessionList);
      notification('error', response?.message);
    } else {
      notification('error', response?.message);
    }
  };

  const resend = async () => {
    setTimer(59);
    methods.reset();
    await loginWithPhone(phone);
  };

  const handleDeleteSession = async () => {
    const response = await confirmOtp({ phone, code });
    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data;
      setAccessToken(cineramaToken);
      setUserCredentials(me);
      authCallbackOnSuccess();
    }
  };

  const transformedTime = ('00' + timer).slice(-2);

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form
          isSubmitting={methods.formState.isSubmitting}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.authForm}
        >
          <Form.Body className={styles.authFormBody}>
            <div className={styles.smsPhoneInfo}>
              <span className={styles.phone}>+998 {normalizePhone(phone)}</span>
              <button type="button" className={styles.editButton} onClick={setOtpStatus}>
                <EditSVG />
              </button>
            </div>
            <Form.Code
              name="code"
              length={6}
              placeholder="-"
              className={styles.codeInput}
              autoFocus
            />
            {timer ? (
              <TextButton
                as="button"
                type="button"
                className={clsx(styles.button, styles.resetCode)}
              >
                {t('verification.resend')}
                <span className={clsx(styles.timer, { [styles.warning]: timer < 11 })}>
                  {timer ? `00:${transformedTime}` : ''}
                </span>
              </TextButton>
            ) : (
              <TextButton
                as="button"
                type="button"
                className={clsx(styles.sendCode)}
                onClick={resend}
              >
                Не получил код?
              </TextButton>
            )}
          </Form.Body>
          <Form.Footer className={styles.formFooter}>
            <Button
              size="lg"
              variant="primary"
              as="button"
              type="submit"
              disabled={methods.formState.isSubmitting}
              className={styles.submitButton}
            >
              {t('common.continue')}
            </Button>
          </Form.Footer>
        </Form>
      </FormProvider>
      <SessionsModal
        sessions={sessions}
        onClose={() => setSessions([])}
        onSessionDelete={handleDeleteSession}
      />
    </Fragment>
  );
};
