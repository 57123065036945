import type { SVGProps } from '@/types/common.types';

const ErrorSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M12.7465 10.0779C12.7035 10.035 12.7035 9.965 12.7465 9.92202L17.9933 4.6726C18.442 4.22379 18.442 3.49346 17.9933 3.04461L16.9532 2.00394C16.7358 1.7864 16.4467 1.66663 16.1392 1.66663C15.8317 1.66663 15.5427 1.7864 15.3252 2.00391L10.0784 7.25339C10.0518 7.27994 10.0216 7.28553 10.0009 7.28553C9.98017 7.28553 9.94987 7.27997 9.92332 7.25339L4.67645 2.00391C4.45903 1.7864 4.16997 1.66663 3.86246 1.66663C3.55496 1.66663 3.26593 1.7864 3.04851 2.00391L2.00837 3.04461C1.55971 3.49346 1.55971 4.22379 2.00837 4.6726L7.25521 9.92202C7.29816 9.965 7.29816 10.035 7.25521 10.0779L2.00837 15.3274C1.55974 15.7762 1.55974 16.5065 2.00837 16.9554L3.04851 17.996C3.26593 18.2135 3.55499 18.3333 3.86246 18.3333C4.16994 18.3333 4.45903 18.2135 4.67645 17.996L9.92329 12.7466C9.94984 12.72 9.9801 12.7145 10.0008 12.7145C10.0216 12.7145 10.0518 12.72 10.0784 12.7466L15.3252 17.9961C15.5426 18.2136 15.8317 18.3333 16.1392 18.3333C16.4467 18.3333 16.7357 18.2136 16.9531 17.9961L17.9933 16.9554C18.4419 16.5066 18.4419 15.7762 17.9933 15.3274L12.7465 10.0779Z"
        fill="white"
      />
    </svg>
  );
};

export default ErrorSVG;
