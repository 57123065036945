'use client';

import { ReactNode } from 'react';

import { isEmpty } from 'lodash';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useEffectOnceWhen } from '@/hooks/useEffectOnceWhen';

import { TransformedSubscription } from '@/api/types/user.types';

import { ScrollToItemSlider } from '@/components/Slider/ScrollToItemSlider';

import { UserPayments } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/UserPayments';
import {
  SelectedSubscriptionInfo,
  SelectedSubscriptionInfoPlaceholder,
} from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SelectedSubscriptionInfo';
import {
  SubscriptionCard,
  SubscriptionPlaceholderCard,
} from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionCard';
import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/SubscriptionRow.module.scss';

// =================================================================

interface SubscriptionRowProps {
  subscriptions: TransformedSubscription[];
  title?: string;
  loadingSubscription: boolean;
}

// =================================================================

export const SubscriptionRow = (props: SubscriptionRowProps) => {
  const { title, subscriptions, loadingSubscription } = props;

  const { setSubscription, subscription: selectedSubscription } = useVerification();

  const handlePurchaseStart = (subscription: TransformedSubscription) => {
    setSubscription(subscription);
  };

  useEffectOnceWhen(() => {
    setSubscription(subscriptions[0]);
  }, !isEmpty(subscriptions));

  const viewFilterSubscription = (subscription: TransformedSubscription, view: ReactNode) => {
    if (!subscription?.isBuyable) return null;
    return view;
  };

  return (
    <div className={styles.subscriptionForm}>
      <div className={styles.subscriptionFormBody}>
        {title && <p className={styles.title}>{title}</p>}

        <ScrollToItemSlider
          xs={1.5}
          sm={1.8}
          md={2.1}
          lg={2.3}
          xl={2.3}
          className={styles.customSwiperSlide}
        >
          {(loadingSubscription || isEmpty(subscriptions)) &&
            Array(9)
              .fill(1)
              .map((_, index) => <SubscriptionPlaceholderCard key={index} />)}

          {subscriptions?.map(subscription => {
            return viewFilterSubscription(
              subscription,
              <SubscriptionCard
                key={subscription.id}
                subscription={subscription}
                name="tariffId"
                value={subscription.id}
                checked={selectedSubscription?.id === subscription.id}
                onPurchaseStart={() => {
                  handlePurchaseStart(subscription);
                }}
              />,
            );
          })}
        </ScrollToItemSlider>

        {(!subscriptions || isEmpty(subscriptions)) && <SelectedSubscriptionInfoPlaceholder />}
        <SelectedSubscriptionInfo subscription={selectedSubscription} />
      </div>

      <UserPayments />
    </div>
  );
};
