import type { SVGProps } from '@/types/common.types';

const CheckedSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <rect y="0.5" width="20" height="20" rx="10" fill="#FFFF4D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7671 7.42207C15.0862 7.75366 15.0762 8.28121 14.7446 8.60037L8.68395 14.4337C8.36128 14.7443 7.85084 14.7443 7.52817 14.4337L5.25544 12.2462C4.92385 11.927 4.91377 11.3995 5.23293 11.0679C5.55209 10.7363 6.07963 10.7262 6.41122 11.0454L8.10606 12.6767L13.5888 7.39955C13.9204 7.08039 14.4479 7.09047 14.7671 7.42207Z"
        fill="#181B21"
      />
    </svg>
  );
};

export default CheckedSVG;
