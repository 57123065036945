export const GooglePlayIconSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88817 3.06957C3.63593 3.34251 3.49023 3.75955 3.49023 4.30542V23.6663C3.49023 24.2122 3.63593 24.6292 3.89687 24.8913L3.96428 24.9502L14.7673 14.1027V13.8603L3.95558 3.01062L3.88817 3.06957Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.361 17.7294L14.7578 14.1114V13.8581L18.361 10.2401L18.4393 10.2881L22.7014 12.7205C23.9213 13.4105 23.9213 14.5503 22.7014 15.249L18.4393 17.6814L18.361 17.7294Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4406 17.6815L14.7591 13.9849L3.88867 24.9001C4.28661 25.328 4.95419 25.3761 5.70005 24.959L18.4406 17.6815Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4406 10.288L5.70005 3.02141C4.95419 2.59344 4.28443 2.6524 3.88867 3.08036L14.7591 13.9847L18.4406 10.288Z"
        fill="white"
      />
    </svg>
  );
};
