export const MailOpenedSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
      <g filter="url(#filter0_d_8525_16838)">
        <path
          d="M47.7379 27.9183C47.7361 27.4539 47.2166 27.1766 46.8218 27.4213L32.9277 36.0317C32.7966 36.1129 32.6482 36.1535 32.4998 36.1535C32.3514 36.1535 32.203 36.1129 32.072 36.0317L18.1778 27.4213C17.783 27.1766 17.2635 27.4539 17.2617 27.9183C17.2617 27.9217 17.2617 27.9251 17.2617 27.9285V42.7541C17.2617 44.1895 18.4254 45.3532 19.8608 45.3532H45.1388C46.5743 45.3532 47.7379 44.1895 47.7379 42.7541V27.9285C47.7379 27.9251 47.7379 27.9217 47.7379 27.9183ZM20.1683 26.7437L32.2003 34.2002C32.3838 34.3139 32.6158 34.3139 32.7993 34.2002L44.8313 26.7437C45.0225 26.6253 45.1388 26.4164 45.1388 26.1914V19.946C45.1388 19.2283 44.557 18.6465 43.8393 18.6465H21.1603C20.4426 18.6465 19.8608 19.2283 19.8608 19.946V26.1914C19.8608 26.4163 19.9771 26.6252 20.1683 26.7437ZM27.6964 21.8953H37.3032C37.7224 21.8953 38.0623 22.2352 38.0623 22.6544C38.0623 23.0736 37.7224 23.4135 37.3032 23.4135H27.6964C27.2772 23.4135 26.9373 23.0736 26.9373 22.6544C26.9373 22.2352 27.2772 21.8953 27.6964 21.8953ZM27.6964 25.4377H37.3032C37.7224 25.4377 38.0623 25.7775 38.0623 26.1967C38.0623 26.6159 37.7224 26.9558 37.3032 26.9558H27.6964C27.2772 26.9558 26.9373 26.6159 26.9373 26.1967C26.9373 25.7775 27.2772 25.4377 27.6964 25.4377Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8525_16838"
          x="-10.1669"
          y="-5.12494"
          width="85.3337"
          height="81.5639"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.65714" />
          <feGaussianBlur stdDeviation="13.7143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0705882 0 0 0 0.3 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8525_16838" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8525_16838"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
