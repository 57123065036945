import type { SVGProps } from '@/types/common.types';

const DoorSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_6149_7115)">
        <path
          d="M48.2937 46.9047V20.7142L29.2461 18.3333V51.6666L48.2937 49.2857H49.881V46.9048H48.2937V46.9047ZM35.1985 37.3809H32.8176V32.619H35.1985V37.3809Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_6149_7115)">
        <path
          d="M26.8652 23.0952V20.7142H21.7064V46.9047H20.1191V49.2857H26.8652V46.9047H24.0874V23.0952H26.8652Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6149_7115"
          x="-0.753906"
          y="-7.66669"
          width="80.6348"
          height="93.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0705882 0 0 0 0.3 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6149_7115" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6149_7115"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6149_7115"
          x="-9.88086"
          y="-5.28577"
          width="66.7461"
          height="88.5715"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0705882 0 0 0 0.3 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6149_7115" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6149_7115"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DoorSVG;
