import { useContext } from 'react';

import { VerificationContext } from '@/contexts/VerificationContext/VerificationContext';

export const useVerification = () => {
  const context = useContext(VerificationContext);

  if (!context) {
    throw new Error('useVerification should be used within VerificationProvider');
  }

  return context;
};
