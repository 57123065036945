import { useTranslations } from 'next-intl';

import type { SessionList } from '@/app/actions/auth/types';

import { Alert } from '@/components/Alert';
import { SessionCard } from '@/components/SessionCard';
import styles from '@/components/SessionsModal/SessionsModal.module.scss';

interface SessionsModalProps {
  onClose: () => void;
  sessions: SessionList[];
  onSessionDelete: () => void;
}

export const SessionsModal = (props: SessionsModalProps) => {
  const { onClose, sessions, onSessionDelete } = props;

  const t = useTranslations('common');

  const onSessionDeleteCallbackFn = () => {
    onSessionDelete();
    onClose();
  };

  return (
    <Alert
      status="success"
      isOpen={sessions.length > 0}
      size="sm"
      title={t('sessionsModalTitle')}
      onClose={onClose}
      className={styles.sessionsModal}
    >
      <ul>
        {sessions.map(session => (
          <SessionCard
            as="li"
            key={session.id}
            session={session}
            onSessionDelete={onSessionDeleteCallbackFn}
            className={styles.listItem}
            hasTerminateButton
          />
        ))}
      </ul>
    </Alert>
  );
};
