import type { SVGProps } from '@/types/common.types';

const TrashbinSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M3.99976 7H19.9998M9.99976 11V17M13.9998 11V17M4.99976 7L5.99976 19C5.99976 19.5304 6.21047 20.0391 6.58554 20.4142C6.96061 20.7893 7.46932 21 7.99976 21H15.9998C16.5302 21 17.0389 20.7893 17.414 20.4142C17.789 20.0391 17.9998 19.5304 17.9998 19L18.9998 7M8.99976 7V4C8.99976 3.73478 9.10511 3.48043 9.29265 3.29289C9.48019 3.10536 9.73454 3 9.99976 3H13.9998C14.265 3 14.5193 3.10536 14.7069 3.29289C14.8944 3.48043 14.9998 3.73478 14.9998 4V7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashbinSVG;
