'use client';

import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import type { Locale } from '@/types/common.types';

import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';

import { UserApi } from '@/api/domains/user-api';
import { UserSubscription } from '@/api/types/user.types';

import { ProfileQueryKeys } from '@/contexts/QueryContext/query.keys';

// =================================================================

export const useShowcasedSubscriptions = () => {
  const locale = useLocale() as Locale;

  const { queryParams } = useSearchParamsResult();

  const { moduleId } = queryParams as { moduleId: string };

  const { data: subscription = [], isLoading } = useQuery({
    queryKey: ProfileQueryKeys.subscriptionCards(moduleId, locale),
    queryFn: () => UserApi.fetchSubscriptions({ module_id: moduleId, locale }),
    staleTime: 0,
    enabled: !!moduleId,
    select: (data: UserSubscription[]) => {
      return data.map(subscription => UserApi.subscriptionDataExtractor(subscription));
    },
  });

  return {
    subscription,
    loadingSubscription: isLoading,
  };
};
