'use client';

import React, { Fragment } from 'react';

import { useTranslations } from 'next-intl';

import { normalizePrise } from '@/helpers/transformations.helpers';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/WriteOffPrice.module.scss';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

// =================================================================

export const WriteOffPrice = () => {
  const t = useTranslations('verification');
  const { writeOff, withdrawCardPrise, withdrawBalancePrise, userCredentials } =
    useSubscriptionVerification();

  return (
    <Fragment>
      <div className={styles.amountContainer}>
        {Boolean(writeOff) && (
          <p className={styles.amount}>
            {t('balance', {
              balance: normalizePrise(userCredentials?.balance),
            })}
          </p>
        )}

        {Boolean(withdrawBalancePrise) && (
          <p className={styles.amount}>
            {t('amountDebitBalance', {
              price: normalizePrise(withdrawBalancePrise),
            })}
          </p>
        )}

        {Boolean(withdrawCardPrise) && (
          <p className={styles.amount}>
            {t('amountDebitCard', {
              price: normalizePrise(withdrawCardPrise),
            })}
          </p>
        )}
      </div>
    </Fragment>
  );
};
