'use client';

import React, { createContext, useContext } from 'react';

import { ChildrenProps } from '@/types/common.types';

import { UserApi } from '@/api/domains/user-api';
import { TransformedSubscription } from '@/api/types/user.types';

import { useStore } from '@/contexts/StoreContext';

// =================================================================

interface BankCardProviderProps extends ChildrenProps {
  responseMessage: string;
  data: any;
  hasOTP: boolean;
  setData: Function;
  setHasOTP: (hasOTP: boolean) => void;
  setResponseMessage: (responseMessage: string) => void;
  purchaseSubscriptionWithCard: (
    subscription: TransformedSubscription,
    openModal: (isOpen: boolean) => void,
  ) => string | Promise<string>;
}

// =================================================================

const BankCardContext = createContext<Omit<BankCardProviderProps, 'children'> | null>(null);

const BankCardProvider = (props: Omit<BankCardProviderProps, 'purchaseSubscriptionWithCard'>) => {
  const { children, setHasOTP, ...rest } = props;

  const { isRenew } = useStore(store => store.verificationStore);
  const { setSubscription } = useStore(store => store.subscriptionStore);

  const purchaseSubscriptionWithCard = async (
    subscription: TransformedSubscription,
    openModal: (isOpen: boolean) => void,
  ) => {
    try {
      await UserApi.cardPayment(Number(subscription?.id), isRenew);
      setSubscription(null);
      openModal(true);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      const { message } = error.response;
      return message;
    }
  };

  const value = {
    ...rest,
    setHasOTP,
    purchaseSubscriptionWithCard,
  };

  return <BankCardContext.Provider value={value}>{children}</BankCardContext.Provider>;
};

export default BankCardProvider;

// =================================================================

export const useBankCard = () => {
  const context = useContext(BankCardContext);

  if (!context) {
    throw new Error('useBankCard should be used within BankCardProvider');
  }

  return context;
};
