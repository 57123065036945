import type { SVGProps } from '@/types/common.types';

const EditSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M5.83203 5.8333H4.9987C4.55667 5.8333 4.13275 6.00889 3.82019 6.32146C3.50763 6.63402 3.33203 7.05794 3.33203 7.49997V15C3.33203 15.442 3.50763 15.8659 3.82019 16.1785C4.13275 16.491 4.55667 16.6666 4.9987 16.6666H12.4987C12.9407 16.6666 13.3646 16.491 13.6772 16.1785C13.9898 15.8659 14.1654 15.442 14.1654 15V14.1666M13.332 4.16663L15.832 6.66663M16.9862 5.48747C17.3144 5.15926 17.4988 4.71412 17.4988 4.24997C17.4988 3.78581 17.3144 3.34067 16.9862 3.01247C16.658 2.68426 16.2129 2.49988 15.7487 2.49988C15.2845 2.49988 14.8394 2.68426 14.5112 3.01247L7.4987 9.99997V12.5H9.9987L16.9862 5.48747Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditSVG;
