import Image from 'next/image';

import { AppleStoreIconSVG } from '@/icons/AppleStoreIconSVG';
import { GooglePlayIconSVG } from '@/icons/GooglePlayIconSVG';

import styles from '@/contexts/VerificationContext/VerificationModal/VerificationBanner.module.scss';

export const VerificationBanner = () => {
  return (
    <div className={styles.verificationBanner}>
      <Image
        fill
        src={'/assets/images/auth-banner.jpg'}
        alt="Banner"
        className={styles.bannerImage}
      />
      <Image
        fill
        src={'/assets/images/auth-overlay.png'}
        alt="overlay"
        className={styles.overlay}
      />
      <div className={styles.phones}>
        <Image fill src={'/assets/images/auth-banner-phones.png'} alt="phones" />
      </div>
      <section className={styles.content}>
        <div className={styles.apps}>
          <a
            target="_blank"
            href={process.env.NEXT_PUBLIC_CINERAMA_APP_APP_STORE_URL}
            className={styles.app}
          >
            <AppleStoreIconSVG />

            <div>
              <p className={styles.appText}>Скачать из</p>
              <p className={styles.appTitle}>Apple Store</p>
            </div>
          </a>
          <a
            target="_blank"
            href={process.env.NEXT_PUBLIC_CINERAMA_APP_GOOGLE_PLAY_URL}
            className={styles.app}
          >
            <GooglePlayIconSVG />
            <div>
              <p className={styles.appText}>Скачать из</p>
              <p className={styles.appTitle}>Google Play</p>
            </div>
          </a>
        </div>
        <h1 className={styles.title}>Скачайте приложение</h1>
        <p className={styles.subtitle}>И смотрите фильмы прямо из вашего смартфона</p>
      </section>
    </div>
  );
};
