import clsx from 'clsx';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { useStore } from '@/contexts/StoreContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerificationTabs.module.scss';

export const AuthVerificationTabs = () => {
  const { loginWithPhone, loginWithUsername } = useVerification();
  const { isLoginWithPhone } = useStore(store => store.verificationStore);

  return (
    <div className={styles.authVerificationTabs}>
      <button
        className={clsx({
          [styles.authVerificationTab]: true,
          [styles.active]: isLoginWithPhone,
        })}
        onClick={loginWithPhone}
      >
        Номер телефона
      </button>
      <button
        className={clsx({
          [styles.authVerificationTab]: true,
          [styles.active]: !isLoginWithPhone,
        })}
        onClick={loginWithUsername}
      >
        C помощью логина
      </button>
    </div>
  );
};
