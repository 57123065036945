import { EffectCallback, useEffect, useRef } from 'react';
import { useLatest } from 'react-use';

// ----------------------------------------------------------------------

export const useEffectOnceWhen = (effect: EffectCallback, when: boolean) => {
  const didCallRef = useRef(false);
  const latestEffect = useLatest(effect);

  useEffect(() => {
    if (!didCallRef.current && when) {
      latestEffect.current();
      didCallRef.current = true;
    }
  }, [latestEffect, when]);
};
