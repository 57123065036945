import type { SVGProps } from '@/types/common.types';

const UserPhoneSVG = (props: SVGProps) => {
  return (
    <svg viewBox="-2 -2 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.2875 11.2125L6.9 12.6C6.6075 12.8925 6.1425 12.8925 5.8425 12.6075C5.76 12.525 5.6775 12.45 5.595 12.3675C4.8225 11.5875 4.125 10.77 3.5025 9.915C2.8875 9.06 2.3925 8.205 2.0325 7.3575C1.68 6.5025 1.5 5.685 1.5 4.905C1.5 4.395 1.59 3.9075 1.77 3.4575C1.95 3 2.235 2.58 2.6325 2.205C3.1125 1.7325 3.6375 1.5 4.1925 1.5C4.4025 1.5 4.6125 1.545 4.8 1.635C4.995 1.725 5.1675 1.86 5.3025 2.055L7.0425 4.5075C7.1775 4.695 7.275 4.8675 7.3425 5.0325C7.41 5.19 7.4475 5.3475 7.4475 5.49C7.4475 5.67 7.395 5.85 7.29 6.0225C7.1925 6.195 7.05 6.375 6.87 6.555L6.3 7.1475C6.2175 7.23 6.18 7.3275 6.18 7.4475C6.18 7.5075 6.1875 7.56 6.2025 7.62C6.225 7.68 6.2475 7.725 6.2625 7.77C6.3975 8.0175 6.63 8.34 6.96 8.73C7.2975 9.12 7.6575 9.5175 8.0475 9.915C8.1225 9.99 8.205 10.065 8.28 10.14C8.58 10.4325 8.5875 10.9125 8.2875 11.2125Z" />
      <path d="M16.4777 13.7475C16.4777 13.9575 16.4402 14.175 16.3652 14.385C16.3427 14.445 16.3202 14.505 16.2902 14.565C16.1627 14.835 15.9977 15.09 15.7802 15.33C15.4127 15.735 15.0077 16.0275 14.5502 16.215C14.5427 16.215 14.5352 16.2225 14.5277 16.2225C14.0852 16.4025 13.6052 16.5 13.0877 16.5C12.3227 16.5 11.5052 16.32 10.6427 15.9525C9.78019 15.585 8.91769 15.09 8.06269 14.4675C7.7702 14.25 7.4777 14.0325 7.2002 13.8L9.65269 11.3475C9.86269 11.505 10.0502 11.625 10.2077 11.7075C10.2452 11.7225 10.2902 11.745 10.3427 11.7675C10.4027 11.79 10.4627 11.7975 10.5302 11.7975C10.6577 11.7975 10.7552 11.7525 10.8377 11.67L11.4077 11.1075C11.5952 10.92 11.7752 10.7775 11.9477 10.6875C12.1202 10.5825 12.2927 10.53 12.4802 10.53C12.6227 10.53 12.7727 10.56 12.9377 10.6275C13.1027 10.695 13.2752 10.7925 13.4627 10.92L15.9452 12.6825C16.1402 12.8175 16.2752 12.975 16.3577 13.1625C16.4327 13.35 16.4777 13.5375 16.4777 13.7475Z" />
    </svg>
  );
};

export default UserPhoneSVG;
