import { TransformedSubscription } from '@/api/types/user.types';

import type { CreateSlice } from '@/zustand-store/store.types';

export type SubscriptionState = {
  subscription: TransformedSubscription | null;
  selectToBuySubscription: TransformedSubscription | null;
  setSubscription: (subscription: TransformedSubscription | null) => void;
  setSelectToBuySubscription: (subscription: TransformedSubscription | null) => void;
};

export type SubscriptionStore = {
  subscriptionStore: SubscriptionState;
};

export const createSubscriptionSlice: CreateSlice<SubscriptionStore> = set => ({
  subscriptionStore: {
    subscription: null,
    selectToBuySubscription: null,
    setSubscription: subscription =>
      set(state => ({
        subscriptionStore: { ...state.subscriptionStore, subscription },
      })),
    setSelectToBuySubscription: subscription =>
      set(state => ({
        subscriptionStore: { ...state.subscriptionStore, selectToBuySubscription: subscription },
      })),
  },
});
