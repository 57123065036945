import { useState, useEffect } from 'react';

import { loadScript } from '@/helpers/load-script';
import { removeScript } from '@/helpers/remove-script';

const noop = () => {};

export const useGoogleLogin = ({
  onSuccess = noop,
  onAutoLoadFinished = noop,
  onFailure = noop,
  onRequest = noop,
  onScriptLoadFailure,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  autoLoad,
  isSignedIn,
  fetchBasicProfile,
  redirectUri,
  discoveryDocs,
  uxMode,
  scope,
  accessType,
  responseType,
  jsSrc = process.env.NEXT_PUBLIC_GOOGLE_LOGIN_DEFAULT_JS_SRC,
  prompt,
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleSigninSuccess = res => {
    /*
      offer renamed response keys to names that match use
    */
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse(true);
    res.googleId = basicProfile.getId();
    res.tokenObj = authResponse;
    res.tokenId = authResponse.id_token;
    res.accessToken = authResponse.access_token;
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    };
    onSuccess(res);
  };

  const signIn = e => {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    if (loaded) {
      const GoogleAuth = window.gapi.auth2.getAuthInstance();
      const options = {
        prompt,
      };
      onRequest();
      if (responseType === 'code') {
        GoogleAuth.grantOfflineAccess(options).then(
          res => onSuccess(res),
          err => onFailure(err),
        );
      } else {
        GoogleAuth.signIn(options).then(
          res => handleSigninSuccess(res),
          err => onFailure(err),
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    const onLoadFailure = onScriptLoadFailure || onFailure;
    loadScript(
      document,
      'script',
      'google-login',
      jsSrc,
      () => {
        const params = {
          client_id: clientId,
          cookie_policy: cookiePolicy,
          login_hint: loginHint,
          hosted_domain: hostedDomain,
          fetch_basic_profile: fetchBasicProfile,
          discoveryDocs,
          ux_mode: uxMode,
          redirect_uri: redirectUri,
          scope,
          access_type: accessType,
        };

        if (responseType === 'code') {
          params.access_type = 'offline';
        }

        window.gapi.load('auth2', () => {
          const GoogleAuth = window.gapi.auth2.getAuthInstance();
          if (!GoogleAuth) {
            window.gapi.auth2.init(params).then(
              res => {
                if (isMounted) {
                  setLoaded(true);
                  const signedIn = isSignedIn && res.isSignedIn.get();
                  onAutoLoadFinished(signedIn);
                  if (signedIn) {
                    handleSigninSuccess(res.currentUser.get());
                  }
                }
              },
              err => {
                setLoaded(true);
                onAutoLoadFinished(false);
                onLoadFailure(err);
              },
            );
          } else {
            GoogleAuth.then(
              () => {
                if (!isMounted) {
                  return;
                }
                if (isSignedIn && GoogleAuth.isSignedIn.get()) {
                  setLoaded(true);
                  onAutoLoadFinished(true);
                  handleSigninSuccess(GoogleAuth.currentUser.get());
                } else {
                  setLoaded(true);
                  onAutoLoadFinished(false);
                }
              },
              err => {
                onFailure(err);
              },
            );
          }
        });
      },
      err => {
        onLoadFailure(err);
      },
    );

    return () => {
      isMounted = false;
      removeScript(document, 'google-login');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoLoad) {
      signIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return { signIn, loaded };
};
