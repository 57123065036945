'use client';

import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useTranslations } from 'next-intl';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { Form } from '@/components/Form';

import styles from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/RenewForm.module.scss';

// =================================================================

interface RenewFormProps {
  isHasRenew?: boolean;
  disabled?: boolean;
}

// =================================================================

export const RenewForm = (props: RenewFormProps) => {
  const { isHasRenew, disabled = false } = props;
  const { isRenew, setRenew } = useVerification();
  const t = useTranslations('verification');

  const methods = useForm<{ is_renew: boolean }>({
    defaultValues: {
      is_renew: isHasRenew || isRenew,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setRenew(methods.getValues('is_renew'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch('is_renew')]);

  return (
    <FormProvider {...methods}>
      <div className={styles.form}>
        <div className={styles.checkboxGroup}>
          <Form.Checkbox name="is_renew" label={t('autoRenewalPlaceholder')} disabled={disabled} />
        </div>
      </div>
    </FormProvider>
  );
};
