'use client';

import { Fragment, useMemo } from 'react';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { HasCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasCard';
import { HasNotCard } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasNotCard';
import { HasRequiredBalance } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/Payments/HasRequiredBalance';
import { useSubscriptionVerification } from '@/contexts/VerificationContext/VerificationModal/SubscriptionVerification/contexts';

export const PaymentWithCard = () => {
  const { userCredentials } = useSubscriptionVerification();
  const { subscription } = useVerification();

  const hasRequiredAmount = useMemo(() => {
    if (subscription?.forcePayWithCard) return false;
    return Number(subscription?.price) <= userCredentials?.balance;
  }, [userCredentials, subscription]);

  return (
    <Fragment>
      {!userCredentials?.card && !hasRequiredAmount && <HasNotCard />}

      {userCredentials?.card && !hasRequiredAmount && <HasCard />}

      {hasRequiredAmount && <HasRequiredBalance />}
    </Fragment>
  );
};
